
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-outline {
  text-shadow: -1px -1px 0 #000,  
                1px -1px 0 #000,
               -1px  1px 0 #000,
                1px  1px 0 #000;  
}

.text-outline-white {
  text-shadow: -0.5px -0.5px 0 #fff,  
                0.5px -0.5px 0 #fff,
               -0.5px  0.5px 0 #fff,
                0.5px  0.5px 0 #fff;  
}

.timeslot {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.timeslot:after, .timeslot:before {
  content: '';
  width: 150px;
  height: 2px;
  background: teal;
  margin: 0 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes scale-in-out {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-scale-in-out {
  animation: scale-in-out 0.5s ease-in-out forwards;
}

.animate-logo {
  animation: App-logo-spin infinite 7s linear;
}

.ease-in-slow {
  transition: max-height 0.3s ease-in;
  overflow: hidden;
}

.max-height-collapsed {
  max-height: 0;
}
.max-height-expanded {
  max-height: 70px; 
}

.expand-animation {
  transition: max-height 0.5s ease-in;
  animation: expand 0.5s ease-in-out backwards;
}

@keyframes expand {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.fundo {
  animation: scales 3s alternate infinite;
  transform-origin: center;
}
.pao-baixo {
  animation: rotatepao 14s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
  transform-origin: center;
}
.pao-cima {
  animation: rotatepao 7s 1s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
  transform-origin: center;
}
.olhos {
  animation: olhos 2s alternate infinite;
  transform-origin: center;
}

.left-sparks {
  animation: left-sparks 4s alternate infinite;
  transform-origin: 150px 156px;
}

.right-sparks {
  animation: left-sparks 4s alternate infinite;
  transform-origin: 310px 150px;
}

.olhos {
  animation: olhos 2s alternate infinite;
  transform-origin: center;
}
@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rotatepao {
  0% {
    transform: rotate(0deg);
  }
  50%,
  60% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes olhos {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(30deg);
  }
}

@keyframes left-sparks {
  0% {
    opacity: 0;
  }
}

.main {
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: dash 4s alternate infinite;
}

@keyframes dash {
  0%,
  30% {
    fill: 4B4B62;
    stroke-dashoffset: 0;
  }
  80%,
  100% {
    fill: transparent;
    stroke-dashoffset: -200;
  }
}

/* custom-scrollbar.css */
::-webkit-scrollbar {
  width: 12px; /* Adjust scrollbar width */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px; /* Roundness */
  border: 3px solid #f1f1f1; /* Padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-25px); }
  75% { transform: translateX(25px); }
}

.shake {
  animation: shake 1s ease-in-out;
}